<template>
    <header class="page-header" style="height: 80px;background-color: rgb(0, 4, 21);">
        <nav class="navbar" style="padding-top: 13px;padding-bottom: 15px;">
            <div class="container-fluid">
                <div class="logo disbk1 disnon"> <router-link to="/index"> <img  src="@/../public/assets/img/logo.png" alt="Image">
                    </router-link>
                </div>
                <div class="col-6 col-md-4 setce">
                    <div style="font-size: 17px;color: #fff;"> <span class="setbtn" style="font-size: 14px;">{{ currentshow.showtime}} </span>
                    </div>
                </div>
                <div class="user-menu" style="display: flow-root;text-align: end;">
                    <div class="setname">{{ currentshow.movie}}
                    </div>
                    <div style="font-size: 12px;color: #fff ;"> <span> {{ Languages[currentshow.lang]}}</span> <span style="color: #fff;">|
                        </span> <span> {{ currentshow.diamen?'3D':'2D'}}
                        </span> <span style="color: #fff;">|
                        </span> <span> {{ currentshow.cert}}
                        </span>
                    </div>
                </div>

            </div>
        </nav>
    </header>
</template>
<script type="text/javascript">
export default {
    props:{
        currentshow:{type:Object}
    },
    data:()=>({
        certclass:{"U":"ce1","U/A":"ce2","A":"ce3"},
        Languages:{"1":"Malayalam",'2':"Tamil",'3':"Hindi",'4':"English",'5':"Telugu",'6':"Kannada"},
    }),
    computed:{

       // StripName : ()=>{
       //      return (MovieTitle) => {
       //      return MovieTitle.length>17?MovieTitle.substr(0,17)+"..":MovieTitle;
       //  }

      // }
    }

}
</script>