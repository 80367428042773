<template>
    <!-- <div class="progress-wrap cursor-pointer"> -->
  <a @click="scrollTop" v-show="visible" class="bottom-right progress-wrap">
    <a class="btn">
        <font-awesome-icon icon="fa-solid fa-location-pin"/>
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" :style="'transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 107.919px, 107.919px; stroke-dashoffset: '+currentScroll+'px;'" />
        </svg>
    </a>
  </a>
  <!-- </div> -->
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      currentScroll:document.body.scrollHeight,
    };
  },
  methods: {
    scrollTop: function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
    },
    scrollListener: function () {
      this.visible = window.scrollY > 150;
      let el=document.body;
      let scrl=(window.scrollY+window.innerHeight+16)/6;
      let wht=(el.scrollHeight)/6;
      this.currentScroll=wht-scrl;
      console.log(scrl,wht)
      
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeUnmount: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style scoped>
.bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}
</style>
