<template>
<div :class="{
      'metaportal_fn_wallet_closer ready': !showModal,
      'metaportal_fn_wallet_closer ready active': showModal,
    }"></div>
    <!-- <button class="btn btn-primary" @click.prevent="showModalTwo = !showModalTwo">Open Modal </button> -->
    <div
    :class="{
      'metaportal_fn_walletbox ready': !showModal,
      'metaportal_fn_walletbox ready active': showModal,
    }"
   
  >
 
    <div class="walletbox">
      <div class="title_holder">
        <div class="customModalTitle">Notes</div>
      </div>

      <div class="list_holder" >
        <ul class="metaportal_fn_items" style="padding-left: 0px">
          <li>
            <div style="border-bottom: dashed;
border-bottom-width: 1px;
border-bottom-color: #ff1e26;" >
              <p style="font-size: 12px;
color: white; ">
1. Entry is allowed only for valid ticket holders.<br>
2. Children above the age of 3 years require tickets.<br>
3. Tickets once purchased cannot be cancelled, exchanged or refunded.<br>
4. Outside food and beverages are not allowed inside the cinema premises.<br>
5. Patrons under the influence of alcohol or drugs will not be allowed inside the Cinema Premises.</p>
            </div>
          </li>
        </ul>
        <div class="customModalFooter">
          <button class="btn btn-rej" @click.prevent="this.$router.push('/')">Cancel </button>
          <button class="btn btn-acc" @click.prevent="showModal = !showModal">Accept </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            text: '',

            showModal: true
        }
    },
}
</script>

<style scoped>
body {
  background-color: #5c4084;
  padding: 50px;
  
}




.btn {
  outline: none !important;
}

.btn.btn-primary {
  background-color: #5c4084;
  border-color: #5c4084;
  outline: none;

}

.hedd{
  color: #FF2A52;
font-size: 17px;
padding-top: 10px;
padding-bottom: 5px;
font-weight: 500;
text-align: left
}

.btn.btn-acc {
  background-color: #ffffff;
 color: #130f15;
  outline: none;
 margin: 5px;

}.btn.btn-rej {
  background-color: #e31e24;
  outline: none;
  color: #ffffff;
 margin: 10px;
}



    .customModalTitle {
        text-align: left;
        padding: 8px 0px;
        font-size: 1.5em;
        border-bottom: dashed;
  border-bottom-width: medium;
border-bottom-width: 1px;
border-bottom-color: #ff1e26;
    }
 
    


    .customModalFooter {
        padding: 4px 0px;
        text-align: right;
    }
</style>