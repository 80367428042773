<template>
  <div style="padding-top: 5px">
    <button
      @click="gotoSeatLayout(show.id,show.isonline)"
      :class="(!show.isonline ?'':'disabled') +' text-center tibtn'"
    v-for="(show,index) in showtimes" :key="index" :style="$route.params.showid==show.id?{'background-color':'#ec9220'}:{ 'background-color': fillColor(show.booked,show.seats,show.isonline) }">
      <span class="linht" > {{ show.stime}}<br>{{ show.screen}} </span></button
    > 
    

  </div>
  <div :class="'modal-mask'+(showModal?' show':'')" @click="showModal=false"><div class="modal-wrapper"><div class="modal-container"><h5 class="text-primary text-center">SORRY</h5><div class="text-danger">{{ warnigText}}</div></div></div></div>
</template>
<style>
.modal-mask{position:fixed;z-index:9998;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.5);display: table;-webki-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   transition: all 0.5s ease;opacity:0;visibility: hidden;}.modal-wrapper{display:table-cell;vertical-align:middle;}.modal-container{width:300px;margin:0px auto;padding:20px 20px 30px;background-color:#fff;border-radius:15px;box-shadow: -1px 11px 70px 7px rgba(120,26,120,1);font-family:Helvetica,Arial,sans-serif;}
.modal-mask.show{visibility: visible;cursor: pointer;opacity: 1}

</style>
<script>
export default {
  props:{
    showtimes:{type:Object},
  },
  data:()=>({
showModal:false,
warnigText:null,
  }),
  methods:{
    gotoSeatLayout(id,isonline){
      this.showModal=isonline;
      this.warnigText="Specified show tickets not available online.Please visit the box office!!";
      if(isonline==0){
        this.$router.push("../book-ticket/"+id);
      }
    },
  fillColor(cnt,seats,ison){
    if(ison!=0){
      return 'rgb(204,204,204)';
    }
  var blprc=cnt/seats;  
  var r = 255,
  g = Math.floor((255 * (1-blprc)));
  g=g>255?255:g;
  var b=g;
  return 'rgb('+r+','+g+','+b+')';
}
  }
};
</script>
