<template>
    <SiteProgress></SiteProgress>
    <SiteHeader></SiteHeader>
    <div class="container-fluid small" style="background-color: #202020;">

        <!-- Mint Top -->
        <div class="metaportal_fn_mint_top row pad50">





            <section class="content-section" style="padding-top: 10px;
    padding-bottom: 0px;">

                <div class="container-fluid">
                    <div class=" justify-content-center">
                        <div class=" row">
                            <!-- CSS Ticket inspired by -->

                            <div class="col-md-12">

                                <h3 class="texh3">ABOUT US</h3>
                                <div class="col-sm-12">
                                    
                                    <p>Founded by Dr. VK Bhaskaran, Sandhya Cine House is a major landmark at its locality in Calicut. The Theatre has more than 30 years of experience in its field since its inception in 1984. With Jeevaraj VK and Yadunath VK at the helm providing the beacon of development, we have evolved to a 2 screen Multiplex incorporating features like 4k Projection, 3D and Dolby Atmos sound system which is the first in Malabar. </p>
                                    
                                </div>
                                <div class="row">
                                <div class="col-md-6">
                                    <div class="hed">OUR VISION</div>

                                    <p>To bring the latest and greatest in cinema experience by employing the state of the art technology in visual and audio systems within the reach of every moviegoer. </p>
                                 
                                </div>
                                <div class="col-md-6">
                                    <div class="hed">OTHER FACILITIES</div>

                                    <p>Snack bar serving delectable bites, Online booking, Ample parking space. </p>
                                   
                               
                            </div></div>

                            <div class="row" style="padding-top: 10px ;"> 
                                
                                <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src="'assets/img/a1.jpg'" /> </div>
                                <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src=" 'assets/img/a2.jpg'" /></div>
                                <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src="'assets/img/a3.jpg'" /></div>
                                 <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src=" 'assets/img/a4.jpg'" /></div>
                                 </div> <div class="row" style=""> 
                                 <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src="'assets/img/a5.jpg'" /> </div>
                                <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src=" 'assets/img/a6.jpg'" /></div>
                                <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src="'assets/img/a7.jpg'" /></div>
                                 <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src=" 'assets/img/a8.jpg'" /></div>
                            
                            </div><div class="row" style=""> 
                                 <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src="'assets/img/a9.jpg'" /> </div>
                                 <div class="col-6 col-sm-3"> <img style="padding-top: 20px ;border-radius: 10px;" class="" :src="'assets/img/a10.jpg'" /> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

</div>





<div>



</div>
<SiteFooter></SiteFooter></template>
<script>

import SiteHeader from '../UI/SiteHeader.vue';
import SiteFooter from '../UI/SiteFooter.vue';
import SiteProgress from '../UI/SiteProgress.vue';
export default {
    
    components: {

        SiteHeader,
        SiteFooter,
        SiteProgress,

    }
}
</script>

