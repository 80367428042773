<template>
  <section class=" combg explore tabs-fade" data-scroll-index="2">
    <div class="container" style="padding-bottom: 20px">
      <div class="row">
        <div class="col-12 xsnopad10">
          <div class="sec-head-bord mb-10">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 rest flex md-no-flex">
                  <div class="sec-head mr-20 md-mb30 mar15">
                    <h4>Coming soon</h4>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 xsnopad">
          <div class="tab-content current feat-card" id="tab-1">
            
            <div class="row" v-if="Results">
              <carousel
                :dots="false"
                :center="true"
                :autoplay="true"
                :loop="true"
                :nav="false"
                :rewind="true"
                :autoplayTimeout="2000"
                :smartSpeed="800"
                :responsiveClass="true"
                :autoHeight="true"
                :responsive="{
                  0: { items: 3,margin: 0 },
                  600: { items: 3,margin: 0, },
                  1024: {
                    items: 5,
                    margin: 10,
                  },
                  1366: {
                    items: 5,
                    margin: 10,
                  },
                }"
              >
                <div
                  class="col-12 col-lg-12 col-md-12 mt-10 xspad"
                  v-for="item in Results"
                  :key="item.title"
                >
                  <div class="item-card">
                    <div class="img">
                      <a href="#0">
                        <img
                          class="bor15"
                          v-if="item.poster"
                          :src="uploads + item.poster"
                          :alt="item.title"
                        />
                      </a>
                    </div>


                    <div class="block-description" style="width: 100%">
                                
                              
                                <div class="hover-buttons" style="padding: 5px;">
                             <span> <span v-if="!item.trailer">{{ item.title }}</span> <a class="venobox font11"
                              data-autoplay="true"
                              data-vbtype="video"
                              v-if="item.trailer"
                              :href="item.trailer">{{ item.title }}</a>  <a  role="button" class="btn btn-hover venobox"
                              data-autoplay="true"
                              data-vbtype="video"
                              v-if="item.trailer"
                              :href="item.trailer">

                              <font-awesome-icon icon="fa-solid fa-play"  aria-hidden="true" />

                               
                                   </a> </span>
                                </div>
                             </div>

                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import venobox from "venobox";
import carousel from "vue-owl-carousel/src/Carousel";
import { URL,UPLOADS } from "../../Conf.js";
import axios from "axios";

export default {

components: {
    carousel,
  },
  data() {
    return {
      Results: null,
      url: URL + "../uploads/",
      uploads:UPLOADS,
      vbox: null,
      
    };
  },
  methods: {
     async getData() {
      axios.get(URL + "commingSoon").then((response) => {
        this.Results = response.data.result;
      }).then(()=>{
        new venobox();
      })
    },


  },

  async created() {
    await this.getData();
    

  },
  async mounted() {
  },

};
</script>

<style>
@import "../../../node_modules/venobox";
</style>
